import './App.css';
import Header from './Header.js';
import CareerMadad from './CareerMadad.js'

function App() {
  return (
    <div className="App">
      <Header />
      <CareerMadad />
      {/* <header className="App-header"> */}
        
      {/* </header> */}
    </div>
  );
}

export default App;
