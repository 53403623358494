import React, { useState, useEffect } from 'react';
import logo from './logo.png';
import './CareerMadad.css';


function CareerMadad() {
  const [careers, setCareers] = useState( [
    {
      orgName: 'RBI',
      logo: 'https://website.rbi.org.in/documents/87730/0/RBI-logo-wText.webp/431b21cc-8c38-1342-cede-804eb8156468?t=1691161600043',
      promotionLink: 'https://opportunities.rbi.org.in/Scripts/bs_viewcontent.aspx?Id=4470',
      overview: 'Overview of RBI as India\'s central bank',
      eligibility: [
        "Eligibility for RBI Grade B and Assistant positions",
        "RBI recruitment process, including phases and selection criteria",
        "Job roles in RBI (Officer, Assistant)",
        "Career growth and training opportunities in RBI",
        "RBI's work culture and employee benefits",
        "Preparation tips for RBI exams",
        "FAQs about RBI recruitment"
      ]
    },
    {
      orgName: 'NABARD',
      logo: 'https://www.nabard.org/images/logo.png',
      promotionLink: 'https://www.nabard.org/careers-notices1.aspx?cid=693&id=26',
      overview: 'Overview of NABARD as India\'s apex development bank',
      eligibility: [
        "Eligibility for NABARD Grade A and B officer positions",
        "NABARD recruitment process, including phases and selection criteria",
        "Job roles in NABARD (Development Officer, Assistant Manager, Manager)",
        "Career growth and training opportunities in NABARD",
        "NABARD's work culture and employee benefits",
        "Preparation tips for NABARD exams",
        "FAQs about NABARD recruitment"
      ]
    },
    {
      orgName: 'SEBI',
      logo: 'https://www.sebi.gov.in/images/sebi-logo.png',
      promotionLink: '',
      overview: 'Overview of SEBI as India\'s securities market regulator',
      eligibility: [
        "Eligibility for SEBI Grade A and B officer positions",
        "SEBI recruitment process, including phases and selection criteria",
        "Job roles in SEBI (Officer, Assistant Manager)",
        "Career growth and training opportunities in SEBI",
        "SEBI's work culture and employee benefits",
        "Preparation tips for SEBI exams",
        "FAQs about SEBI recruitment"
      ]
    },
    {
      orgName: 'SBI',
      logo: 'https://www.logo-designer.co/storage/2017/04/2017-Design-Stack-new-logo-design-State-Bank-of-India.png',
      promotionLink: '',
      overview: 'Overview of SBI as India\'s largest commercial bank',
      eligibility: [
        "SBI is India's largest commercial bank...",
        "Eligibility for various SBI exams (PO, Clerk, Specialist Officer)",
        "Detailed explanation of the SBI recruitment process",
        "Job profiles within SBI (General Banking Officer, Specialist Cadre, Management Trainee)",
        "Career growth opportunities in SBI",
        "SBI's work culture and employee benefits",
        "Preparation tips for SBI exams",
        "FAQs about SBI recruitment"
      ]
    },
  ]);

  useEffect(() => {
    // Fetch career data from an API or static data
    const fetchCareers = async () => {
      try {
        const response = await fetch('https://api.example.com/careers');
        const data = await response.json();
        setCareers(data);
      } catch (error) {
        console.error('Error fetching career data:', error);
      }
    };

    fetchCareers();
  }, []);

  const renderCareerMadad = () => {
    return careers.map((data, index) => (
      <div key={index}>
        {/* <div className="career-madad-container"> */}
          <img src={data.logo} alt="Logo" className="logo" />
          <div className="career-madad-text"> {data.orgName} Career Madad</div>
        {/* </div> */}
        <p>{data.promotionLink ? <a href={data.promotionLink}>View Job Openings</a> : ''}</p>
        <p>{data.overview}</p>
        {data.eligibility.map((el, i) => (
          <i>{el}<br/></i>
        ))}
      </div>
    ));
  }

  return (
    <div className="career-madad">
      <div>
        <img src={logo} className="App-logo" alt="logo" />
        <p>Dreaming of a fulfilling career in banking or finance? </p>
        <p>Discover your potential with SBI, NABARD, RBI, or SEBI.</p> 
        <p>Reach out to us today!</p>
      <div>
        <a href="mailto:careermadad@gmail.com"> <b>careermadad@gmail.com</b></a>
      </div>
      </div>
      <h1>Explore Your Career Path</h1>
      <div className="career-madad-container">
        {renderCareerMadad()}
      </div>
    </div>
  );
}

export default CareerMadad;
